import React from 'react';

const Home = React.lazy(() => import('./containers/home/Home'));
const Layout = React.lazy(() => import('./containers/layout/Layout'));
const KyprolisLayout = React.lazy(() => import('./containers/layout/KyprolisLayout'));



const routes = [
    { path: '/', exact: true, name: 'Home', component: Home },
    { path: '/nplate', exact: false, name: 'Layout', component: Layout },
    // { path: '/kyprolis', exact: false, name: 'KyprolisLayout', component: KyprolisLayout },

]

export default routes;