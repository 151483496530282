// import logo from './logo.svg';
import './App.css';

import React, { useState, useEffect } from "react";
import { Switch, Route, HashRouter, Redirect } from "react-router-dom";

// private routes
import PrivateRoute from './privateRoute.js';
import routes from './routes.js';
import ReactGA from 'react-ga';
import CookieConsent, {getCookieConsentValue} from "react-cookie-consent";


const TRACKING_ID = "UA-250155630-1"; // OUR_TRACKING_ID
ReactGA.initialize(TRACKING_ID);

const Login = React.lazy(() => import("./containers/login/Login"));
const Signup = React.lazy(() => import("./containers/signup/Signup"));
const ResetPassword = React.lazy(() => import("./containers/reset-password/ResetPassword"));

const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
);



function App() {
  const [acceptCookie, setAcceptCookie] = useState(false);
  
useEffect(() => {
  const acceptCookie = getCookieConsentValue("amgenHubCookie");
  if(acceptCookie) setAcceptCookie(true);
}, [])
  return (
    <div>
    <HashRouter>
        <React.Suspense fallback={loading}>
          <Switch>
            {routes.map((route, idx) => {
              return route.component && (
                <PrivateRoute
                  isAuthenticated={true}
                  key={idx}
                  path={route.path}
                  exact={route.exact}
                  name={route.name}
                  render={props => (
                    <>
                      <route.component {...props} />
                    </>
                  )} />
              )
            })}

            {/* <Route exact path="/login" name="Login Page" render={(props) => <Login {...props} />}/>
            <Route exact path="/signup" name="Signup Page" render={(props) => <Signup {...props} />}/>
            <Route exact path="/reset-password" name="Reset Password Page" render={(props) => <ResetPassword {...props} />}/> */}
            <Redirect from="/" to="#" />
          </Switch>
        </React.Suspense>
      </HashRouter>
      {acceptCookie ? <></> : 
      <CookieConsent 
                buttonText="Accept | قبول" 
                debug={true}
                style={{ background: "white", color:"#5a5a5a", fontFamily:"MyriadPro", fontSize: "20px" ,borderRadius:"30px" }}
                buttonStyle={{ background: "#00bce4",color:"white", fontFamily:"MyriadPro", fontSize: "20px" }}
                expires={365}
                cookieName="amgenHubCookie"
                onAccept={()=>{setAcceptCookie(true)}}
                >
              "We use cookies and other technologies to personalize content and ads. By clicking “Accept” you are directing Amgen to disclose your personal information to third parties for the purposes stated above | نحن نستخدم ملفات تعريف الارتباط والتقنيات الأخرى لتخصيص المحتوى والإعلانات. بالنقر على "قبول" ، فإنك تصرح بالكشف عن معلوماتك الشخصية لأطراف ثالثة للأغراض المذكورة أعلاه"
      </CookieConsent>
      }
    </div>
  )
}

export default App;
